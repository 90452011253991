import request from '@/utils/request'

/**
  * 登录
  */
export function loginApi(data = {}) {
  return request({
    url: 'admin/login',
    method: 'POST',
    data
  })
}

/**
  * 投诉列表
  */
export function judgeReportListApi(data = {}) {
  return request({
    url: 'admin/judgeReportList',
    method: 'POST',
    data
  })
}

/**
  * 投诉裁决
  */
export function judgeReportApi(data = {}) {
  return request({
    url: 'admin/judgeReport',
    method: 'POST',
    data
  })
}

/**
  * 审核整改列表
  */
export function auditRectifyListApi(data = {}) {
  return request({
    url: 'admin/auditRectifyList',
    method: 'POST',
    data
  })
}

/**
  * 审核整改详情
  */
export function auditRectifyDetailApi(data = {}) {
  return request({
    url: 'admin/auditRectifyDetail',
    method: 'POST',
    data
  })
}

/**
  * 审核整改
  */
export function auditRectifyApi(data = {}) {
  return request({
    url: 'admin/auditRectify',
    method: 'POST',
    data
  })
}

/**
  * 审核商户撤销申请
  */
export function auditReportAppealApi(data = {}) {
  return request({
    url: 'admin/auditReportAppeal',
    method: 'POST',
    data
  })
}

/**
  * 查询投诉记录
  */
export function reportsApi(data = {}) {
  return request({
    url: 'admin/reports',
    method: 'POST',
    data
  })
}

/**
  * 查询商户信息
  */
export function merchantInfoApi(data = {}) {
  return request({
    url: 'admin/merchantInfo',
    method: 'POST',
    data
  })
}

/**
  * 商户的兼职投放意向管理
  */
export function intentionMangeApi(data = {}) {
  return request({
    url: 'admin/intentionMange',
    method: 'POST',
    data
  })
}

/**
  * 搜索用户信息
  */
export function searchUserApi(data = {}) {
  return request({
    url: 'admin/searchUser',
    method: 'POST',
    data
  })
}

/**
  * 搜索用户报名信息
  */
export function searchEnrollsApi(data = {}) {
  return request({
    url: 'admin/searchEnrolls',
    method: 'POST',
    data
  })
}

/**
  * 获取客服的imToken
  */
export function customerTokenApi(data = {}) {
  return request({
    url: 'admin/customerToken',
    method: 'POST',
    data
  })
}

/**
  * 用户状态管理
  */
export function userStatusOperateApi(data = {}) {
  return request({
    url: 'admin/userStatusOperate',
    method: 'POST',
    data
  })
}

/**
  * 商户赔付申请记录列表
  */
export function claimRecListApi(data = {}) {
  return request({
    url: 'admin/claimRecList',
    method: 'POST',
    data
  })
}

/**
  * 审核商户赔付申请
  */
export function auditClaimApi(data = {}) {
  return request({
    url: 'admin/auditClaim',
    method: 'POST',
    data
  })
}

/**
  * 商户投诉列表
  */
export function reportResumeListApi(data = {}) {
  return request({
    url: 'admin/reportResumeList',
    method: 'POST',
    data
  })
}

/**
  * 审核商户投诉
  */
export function auditReportResumeApi(data = {}) {
  return request({
    url: 'admin/auditReportResume',
    method: 'POST',
    data
  })
}

/**
  * 修正信息
  */
export function correctInfoApi(data = {}) {
  return request({
    url: 'admin/correctInfo',
    method: 'POST',
    data
  })
}

/**
  * 查询发布职位/报名职位
  */
export function theirPostsApi(data = {}) {
  return request({
    url: 'admin/theirPosts',
    method: 'POST',
    data
  })
}

/**
  * 查询系统岗位列表
  */
export function systemPostsApi(data = {}) {
  return request({
    url: 'admin/systemPosts',
    method: 'POST',
    data
  })
}


/**
  * 编辑常用问答或防诈指南
  */
export function editGuideApi(data = {}) {
  return request({
    url: 'admin/editGuide',
    method: 'POST',
    data
  })
}

/**
  * 查询常用问答列表或防诈指南
  */
export function queryAdminGuidesApi(data = {}) {
  return request({
    url: 'admin/queryAdminGuides',
    method: 'POST',
    data
  })
}

/**
  * 查看产品反馈
  */
export function queryFeedbackApi(data = {}) {
  return request({
    url: 'admin/queryFeedback',
    method: 'POST',
    data
  })
}

/**
  * 查询商户头像审核列表
  */
export function merchantAuditAvatarApi(data = {}) {
  return request({
    url: 'admin/merchantAuditAvatar',
    method: 'POST',
    data
  })
}

/**
  * 审核商户头像修改
  */
export function auditMerchantAvatarApi(data = {}) {
  return request({
    url: 'admin/auditMerchantAvatar',
    method: 'POST',
    data
  })
}

/**
  * 查询企业简称审核列表
  */
export function enterpriseAuditShortNamesApi(data = {}) {
  return request({
    url: 'admin/enterpriseAuditShortNames',
    method: 'POST',
    data
  })
}

/**
  * 查询企业简称审核列表
  */
export function auditEnterpriseShortNameApi(data = {}) {
  return request({
    url: 'admin/auditEnterpriseShortName',
    method: 'POST',
    data
  })
}

/**
  * 查询社区话题
  */
export function queryTopicApi(data = {}) {
  return request({
    url: 'admin/comm/queryTopic',
    method: 'POST',
    data
  })
}

/**
  * 查询社区动态
  */
export function queryMomentApi(data = {}) {
  return request({
    url: 'admin/comm/queryMoment',
    method: 'POST',
    data
  })
}

/**
  * 查询社区回复
  */
export function queryReplyApi(data = {}) {
  return request({
    url: 'admin/comm/queryReply',
    method: 'POST',
    data
  })
}

/**
  * 查询社区评论
  */
export function queryCommentApi(data = {}) {
  return request({
    url: 'admin/comm/queryComment',
    method: 'POST',
    data
  })
}

/**
  * 修改社区评论状态
  */
export function editCommentApi(data = {}) {
  return request({
    url: 'admin/comm/editComment',
    method: 'POST',
    data
  })
}

/**
  * 修改社区动态状态
  */
export function editMomentApi(data = {}) {
  return request({
    url: 'admin/comm/editMoment',
    method: 'POST',
    data
  })
}

/**
  * 修改社区话题状态
  */
export function editTopicApi(data = {}) {
  return request({
    url: 'admin/comm/editTopic',
    method: 'POST',
    data
  })
}

/**
  * 修改社区回复状态
  */
export function editReplyApi(data = {}) {
  return request({
    url: 'admin/comm/editReply',
    method: 'POST',
    data
  })
}


/**
  * 查询动态详情
  */
export function queryMomentsDetailsApi(data = {}) {
  return request({
    url: 'admin/comm/queryMomentsDetails',
    method: 'POST',
    data
  })
}

/**
  * 查询回复详情
  */
export function queryReplyDetailsApi(data = {}) {
  return request({
    url: 'admin/comm/queryReplyDetails',
    method: 'POST',
    data
  })
}

/**
  * 批量修改动态状态
  */
export function batchEditMomentApi(data = {}) {
  return request({
    url: 'admin/comm/batchEditMoment',
    method: 'POST',
    data
  })
}

/**
  * 批量修改评论状态
  */
export function batchEditCommentApi(data = {}) {
  return request({
    url: 'admin/comm/batchEditComment',
    method: 'POST',
    data
  })
}

/**
  * 批量修改回复状态
  */
export function batchEditReplyApi(data = {}) {
  return request({
    url: 'admin/comm/batchEditReply',
    method: 'POST',
    data
  })
}

/**
  * 批量修改话题状态
  */
export function batchEditTopicApi(data = {}) {
  return request({
    url: 'admin/comm/batchEditTopic',
    method: 'POST',
    data
  })
}

/**
  * 解除认证绑定
  */
export function unfastenAuthBindApi(data = {}) {
  return request({
    url: 'admin/unfastenAuthBind',
    method: 'POST',
    data
  })
}

/**
  * 岗位刷新时间
  */
export function postRefreshTimeApi(data = {}) {
  return request({
    url: 'admin/postRefreshTime',
    method: 'POST',
    data
  })
}

/**
  * 岗位轮转配置管理
  */
export function postRoundManageApi(data = {}) {
  return request({
    url: 'admin/postRound/postRoundManage',
    method: 'POST',
    data
  })
}

/**
  * 查询岗位轮转配置快照
  */
export function queryPostRoundSnapshotApi(data = {}) {
  return request({
    url: 'admin/postRound/queryPostRoundSnapshot',
    method: 'POST',
    data
  })
}

