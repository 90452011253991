import request from '@/utils/request'

/**
  * 登录
  */
export function unifyLoginApi(data = {}) {
  return request({
    url: 'opera/sys/sysUser/unifyLogin',
    method: 'POST',
    data,
    isOpera: true
  })
}

/**
  * 获取路由菜单
  */
export function getMenusApi(data = {}) {
  return request({
    url: 'opera/sys/permission/getMenus',
    method: 'POST',
    data,
    isOpera: true
  })
}

/**
  * 获取字典数据（渠道/应用）
  */
export function dictListApi(data = {}) {
  return request({
    url: 'opera/sys/dict/list',
    method: 'POST',
    data,
    isOpera: true
  })
}

