import Vue from 'vue'
import store from '@/store'

Vue.directive('auth', {
  inserted: (el, binding, vnode) => {
    console.log('v-auth', binding.value)
    const arr = Array.isArray(binding.value) ? binding.value : [binding.value]
    if(!checkAuth(arr)) {
      el.parentNode?.removeChild(el)
    }
  }
})

export function checkAuth(vals) {
  const authMap = {
    'AUDIT_POST': 1, //审核职位
    'AUDIT_PERSONAL_AUTH': 2, //审核个人认证
    'AUDIT_ENTERPRISE_AUTH': 3, //审核企业认证
    'QUERY_POST_CATEGORY': 4, //查看职位类型信息
    'UPDATE_POST_CATEGORY': 19, //查看职位类型信息
    'CUSTOMER_MESSAGE': 5, //客服消息
    'MODIFY_CPC': 6, //修改cpc
    'CHARGE_TICKET': 7, //充值点券
    'CHARGE_BAIL': 8, //充值保证金
    'CHARGE_ENROL_FORM': 9, //充值报名单
    'USER_STATUS_MANAGE': 10, //用户状态管理
    'MERCHANT_STATUS_MANAGE': 11, //商户状态管理
    'CONFIG_POST_SPECIFY': 12, //配置指定职位
    'SEARCH_POST': 13, //搜索岗位
    'JUDGE_REPORT': 16, //裁决投诉
    'AUDIT_RECTIFY': 17, //审核整改
    'RES_CONFIG': 18, //资源配置
    'SEARCH_REPORT': 20, //搜索投诉记录
    'SEARCH_ENROLL': 21, //搜索报名记录
    'SEARCH_INTENTIONS': 22, //搜索岗位发布意向
    'MODIFY_INTENTIONS': 23, //编辑发布意向
    'MODIFY_USER_INFO_ALLOW_LIST': 24, //编辑用户敏感信息查看白名单
    'MODIFY_ENROLL_RISK_ALLOW_LIST': 25, //编辑风险报名用户白名单
    'UNFAST_MRTCHANT_IDENFITY': 37, //解绑企业认证
  }
  const authCodes = store.state.authCodes || []
  if(authCodes.length == 0) return false
  for (const val of vals) {
    if(authCodes.indexOf(authMap[val]) !== -1) return true
  }
  return false
}