<template>
  <div class="layout">
    <header>
      <div class="logo-title">
        <img src="@/assets/images/logo.png">
        <span>职堆堆后台管理系统</span>
      </div>
      <el-dropdown>
        <div class="btn-user">
          <img class="avatar" src="@/assets/images/logo.png">
          <span>{{userId}}</span>
          <img class="arrow-down" src="@/assets/images/arrow-down.png">
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <div class="user-dropdown" @click="handleLogout">
              <img src="@/assets/images/user-logout.png">
              <span>退出</span>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </header>
    <main>
      <div class="nav-menu">
        <el-menu :default-active="activeMenu" unique-opened>
          <template v-for="(item, index) in menuList">
            <el-submenu v-if="item.children && item.children.length > 0" :index="item.path" :key="index">
              <template slot="title">
                <!-- <img v-if="activeMenu == item.path" :src="getIcon(item.icon+'-s')"> -->
                <img :src="getIcon(item.icon)">
                <span slot="title">{{item.name}}</span>
              </template>
              <el-menu-item :index="citem.path" v-for="(citem, i) in item.children" :key="i" @click="menuClick(citem)">
                <span slot="title">{{citem.name}}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item style="position: relative;" v-else :index="item.path" :key="index" @click="menuClick(item)">
              <img v-if="activeMenu == item.path" :src="getIcon(item.icon+'-s')">
              <img v-else :src="getIcon(item.icon)">
              <span slot="title">{{item.name}}</span>
              <div class="unread-count" v-if="item.path == '/message' && unread != 0">{{unread > 99 ? '99+':unread}}</div>
            </el-menu-item>
          </template>
          
        </el-menu>
      </div>
      <div class="flex-1 overflow-auto">
        <div class="nav-path" v-if="navPathList.length > 0">
          <el-breadcrumb separator="/">
            <template v-for="(item, index) in navPathList" >
              <el-breadcrumb-item v-if="index != navPathList.length-1" :to="{ path: item.path }" :key="index">{{item.meta.title}}</el-breadcrumb-item>
              <el-breadcrumb-item v-else :key="index">{{item.meta.title}}</el-breadcrumb-item>
            </template>
          </el-breadcrumb>
        </div>
        <keep-alive :include="['ConfigList','ReportList', 'ColumnEdit', 'UserList', 'MerchantList', 'EnrollRecord']">
          <router-view/>
        </keep-alive>
      </div>
    </main>
  </div>
</template>

<script>
import EventType from "@/wfc/client/wfcEvent"
import { connectIM } from '@/utils/jim/im.js'
import { mapState } from 'vuex'
import { notificationUtil } from '@/utils/notification.js'
import logo from '@/assets/images/logo.png'
export default {
  name: 'Layout',
  data() {
    return {
      navPathList: [],
      activeMenu: '',
      connectStatus: 0
    }
  },
  computed: {
    ...mapState(['menuList', 'userId']),
    ...mapState('im', ['unread']),
  },
  watch: {
    $route: {
      handler(route) {
        this.navPathList = route.matched.filter(item => item.meta && item.meta.title)
      },
      immediate: true
    },
    menuList: {
      handler(newVal) {
        const index = this.menuList.findIndex(item => item.path == '/message')
        if(index !== -1) {
          this.connectIm()
        }
      },
      immediate: true
    }
  },
  methods: {
    handleLogout() {
      this.$confirm('确认退出系统？').then(action => {
        if(action == 'confirm') this.$store.commit('LOGOUT')
      }).catch(err => {})
    },
    getIcon(iconName) {
      try {
        return require(`@/assets/images/${iconName}.png`)
      } catch (err) {
        return ''
      }
    },
    menuClick(info) {
      this.$router.push(info.path)
      this.activeMenu = info.path
    },
    connectIm() {
      connectIM()
      this.$im.eventEmitter.on(EventType.ReceiveMessage, e => {
        console.log('------ReceiveMessage-----', e)
        this.setUnread()
        if(e?.length > 0 && !this.$route.path.includes('/message')) {
          notificationUtil('职堆堆后台', {
            body: this.getMsgContent(e[0]),
            icon: logo
          })
        }
      })
      this.$im.eventEmitter.on('conversationInfoLoaded', () => {
        console.log('------conversationInfoLoaded-----')
        this.setUnread()
      })
      this.$im.eventEmitter.on(EventType.ConnectionStatusChanged, status => {
        console.log('连接状态', status)
        if(status == 1 && this.connectStatus != 1) {
          this.setUnread()
        }
        this.connectStatus = status
      })
    },
    setUnread() {
      console.log('------setUnread-------')
      this.$store.dispatch('im/UPDATE_UNREAD')
    },
    getMsgContent(lastMsg) {
      if(!lastMsg) return ''
      const msgContent = lastMsg.messageContent || lastMsg.content
      const type = msgContent.type
      if(type == 1 || type == 90) return msgContent.content || msgContent.tip
      const textMap = {
        3: '[图片]',
        4:'[位置]',
        2001:'[位置]',
        2002:'[请求交换联系方式]',
        2003:'[完善联系方式]',
        2004:'[职位卡片]',
        2005:'[报名卡片]',
        2006:'[获取商家联系方式]',
        2007:'[联系方式]',
        1001:'[任务卡片]'
      }
      return textMap[type]
    },
  }
}
 </script>

<style lang="scss" scoped>
.layout {
  min-width: 1200px;
  // overflow-x: auto;
}
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  background-color: #001A18;
  color: #fff;
}
.logo-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #fff;
  img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
}
.btn-user {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  .avatar {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
  }
  .arrow-down {
    width: 12px;
    height: 12px;
    margin-left: 4px;
  }
}
.user-dropdown {
  display: flex;
  align-items: center;
  height: 46px;
  line-height: 46px;
  color: #65666F;
  font-size: 14px;
  cursor: pointer;
  img {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
}

main {
  display: flex;
  background-color: #EEF1F4;
  min-height: 100vh;
}
.nav-menu {
  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 32px;
  }

  &::-webkit-scrollbar-track {
    background-color: #F6F6F6;
    border-radius: 32px;
  }
  width: 208px;
  height: calc(100vh - 48px);
  overflow-y: auto;
  font-size: 14px;
  background-color: #fff;
  ::v-deep .el-menu-item.is-active {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 56px;
      width: 3px;
      background-color: var(--theme-color);
    }
  }
  img {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
}
.nav-path {
  background-color: #fff;
  padding: 16px 24px 8px;
}

.unread-count {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 10px;
  background-color: var(--theme-color-red);
  color: #fff;
}
</style>