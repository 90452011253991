// const baseURL = 'http://192.168.0.104:1688/' // 本地
const baseURL = 'http://118.178.143.133/' // 测试环境 https://apidev.xuniuwang.com
// const baseURL = 'https://zddtiny.taotaojz.com/'  // 正式环境

// const operaBaseUrl = 'http://192.168.0.110:8090/' // 本地
const operaBaseUrl = 'http://118.178.143.133/' // 测试环境
// const operaBaseUrl = 'https://opera.hulianzhongzhi.com/' // 正式环境

module.exports = {
  // HTTP_REQUEST_URL: baseURL,
  HTTP_REQUEST_URL: process.env.VUE_APP_API_URL,
  OPERA_REQUEST_URL: process.env.VUE_APP_OPERA_API_URL,
  PWD_KEY: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAh4C5CzmAtVQIcDaI/UD8uOlkvvX8g+IuQCA1ax+h9fIa9bG148Tq1sXCOujkH9ER+1Oa6hFd2LM0fUeCjUQ3CSR61IGkpA226M2OnqXl8Koa1e6lZkBaSKOLu7sem9aKhnMJpaD389fQ349FLELH42keBpD0wp30RT6QSxjmqkLLbGzgbrCLIs/MCRAxvFiGTUoGk3pHPM6CWDLyvlDwBTFHMSYpLNP3OLBH9sfroX4m0eyAiSSzkneqyozEl8YQyG0T404YfHGUQSaqkwY6lkOFuS83KgM/flFwqkSPDUhoVrtpaqib9GcQsE2rzPA7kK4eFwn1upxzoynNtbHTYQIDAQAB',
}