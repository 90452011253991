import JimClient from 'jim-client'
import { customerTokenApi } from '@/api/admin.js'
import { getImTokenApi } from '@/api/message.js'
import Conversation from "@/wfc/model/conversation"
import Message from "@/wfc/messages/message"
import TextMessageContent from "@/wfc/messages/textMessageContent"
import ImageMessageContent from "@/wfc/messages/imageMessageContent"
import LocationMessageContent from "@/wfc/messages/locationMessageContent"

export function connectIM() {
	console.log('连接im')
	return new Promise((resolve, reject) => {
    customerTokenApi({
      clientId: JimClient.getClientId()
    }).then(res => {
      console.log('im connect', res)
      const { userId, token } = res.data
      JimClient.connect(userId, token)
      resolve()
    }).catch(err => {
      resolve()
    })
	})
}

// 创建会话
export function createConversation({type, targetId, line}) {
	let conversation = new Conversation(type || 0, targetId, line || 0)
	return conversation
}

// 发送消息
/**
 * @param type String  文本-text, 图片-image, 位置-location
 * @param conversation 当前会话
 * 
 */
export function sendMessageApi({type, conversation, value: val}) {
	return new Promise((resolve, reject) => {
		const newMsg = new Message()
		newMsg.conversation = conversation
		// 发送文本
		if(type == 'text') {
			const textContent = new TextMessageContent(val)
			newMsg.messageContent = textContent
		}
		
		// 发送图片
		if(type == 'image') {
			const imgContent = new ImageMessageContent()
			imgContent.remotePath = val
			newMsg.messageContent = imgContent
		}
		
		// 发送位置
		if(type == 'location') {
			let localContent = new LocationMessageContent();
			localContent.type = 4
			localContent.lat = val.lat
			localContent.long = val.lng
			localContent.title = val.address
			newMsg.messageContent = localContent
		}
		
		JimClient.sendMessage(newMsg, uid => {
			// const msg = IM.getMessageByUid(uid)
			resolve(newMsg)
		}, err => {
			reject(err)
		})
	})
	
}