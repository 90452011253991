import axios from 'axios'
import localforage from "localforage"
const CryptoJS = require('crypto-js');
const SECRET_KEY = CryptoJS.enc.Utf8.parse("N2841A3412APCD6F"); //十六位十六进制数作为密钥
const SECRET_IV = CryptoJS.enc.Utf8.parse('AUCDTF12H41P34Y2'); //十六位十六进制数作为密钥偏移量

export function jimDoRequest ({url, body, header}) {
	body = textEncoder(body).buffer
	return new Promise((resolve, reject) => {
		const instance = axios.create()
		const options = {
			url,
			data: body,
			method: 'POST',
			headers: header,
			responseType : 'arraybuffer'
		}
		// console.log('jim 请求', options)
		instance(options).then(res => {
			res.data = new Uint8Array(res.data)
			resolve(res)
		}).catch(err => {
			reject(err)
		})
	})
}

function textEncoder(str) {
	let data = unescape(encodeURIComponent(str)).split('').map(val => val.charCodeAt(0));
	return typeof Uint8Array == 'function' ? new Uint8Array(data) : data;
}

export const jimStore =  {
	_setItem: function(key, data) {
		return new Promise((resolve, reject) => {
			let storageData = this.AesEncrypt(JSON.stringify(data))
			localforage.setItem(key, storageData).then(value => {
				// 当值被存储后，可执行其他操作
				resolve(true)
			}).catch(err => {
				// 当出错时，此处代码运行
				console.log(err);
			});
		})
	},
	_setItemSync: function(key, data) {
		let storageData = this.AesEncrypt(JSON.stringify(data))
		localforage.setItem(key, storageData).then(function (value) {
		  // 当值被存储后，可执行其他操作
		}).catch(function(err) {
			// 当出错时，此处代码运行
			console.log(err);
		});
	},
	_getItem: function(key) {
		return new Promise((resolve, reject) => {
			localforage.getItem(key).then(value => {
				// 当离线仓库中的值被载入时，此处代码运行
				if(value){
					resolve(JSON.parse(this.AesDecode(value)))
				}else{
					resolve(undefined)
				}
			}).catch(err => {
					// 当出错时，此处代码运行
					console.log(err);
			});
		})
	},
	_getItemSync: function(key) {
		if(localStorage.getItem(key)){
			let storageData = JSON.parse(this.AesDecode(localStorage.getItem(key)))
			return storageData
		}else{
			return undefined
		}
	},
	_removeItem: function(key) {
		return new Promise((resolve, reject) => {
			// localStorage.removeItem(key)
			// resolve(true)
			localforage.removeItem(key).then(() => {
				// 当值被移除后，此处代码运行
				resolve(true)
			}).catch(err => {
				// 当出错时，此处代码运行
				console.log(err);
			});
		})
	},
	_removeItemSync: function(key) {
		localforage.removeItem(key).then(() => {
			// 当值被移除后，此处代码运行
		}).catch(err => {
				// 当出错时，此处代码运行
		});
	},
	_clear: function() {
		return new Promise((resolve, reject) => {
			// localStorage.clear()
			// resolve(true)
			localforage.clear().then(function() {
				// 当数据库被全部删除后，此处代码运行
				resolve(true)
			}).catch(function(err) {
				// 当出错时，此处代码运行
				console.log(err);
			});
		})
	},
	_clearSync: function() {
		// localStorage.clear()
		localforage.clear().then(function() {
			// 当数据库被全部删除后，此处代码运行
			console.log('Database is now empty.');
		}).catch(function(err) {
			// 当出错时，此处代码运行
			console.log(err);
		});
	},

	/**
	 * AES 加密
	 */
	AesEncrypt(word) {
		let srcs = CryptoJS.enc.Utf8.parse(word);
		let encrypted = CryptoJS.AES.encrypt(srcs, SECRET_KEY, {
			iv: SECRET_IV,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		return encrypted.ciphertext.toString().toUpperCase();
	},
	/**
	 * AES 解密
	 */
	AesDecode(word) {
		let encryptedHexStr = CryptoJS.enc.Hex.parse(word);
		let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
		let decrypt = CryptoJS.AES.decrypt(srcs, SECRET_KEY, {
			iv: SECRET_IV,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});
		let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
		return decryptedStr.toString();
	}
}