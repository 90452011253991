import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout'
import { getMenusApi } from '@/api/opera.js'
import { $tips } from '@/plugins/element/element.js'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/',
    name: 'layout',
    component: Layout
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/error/404.vue')
  },
  {
    path: '/detail',
    component: Layout,
    redirect: '',
    children: [
      {
        path: 'user',
        name: 'UserDetail',
        component: () => import('@/views/user/detail/index.vue')
      },
      {
        path: 'merchant',
        name: 'MerchantDetail',
        component: () => import('@/views/merchant/detail/index.vue')
      }
    ]
  }
  // {
  //   path: '/message',
  //   component: Layout,
  //   redirect: '/message/list',
  //   children: [
  //     {
  //       path: 'list',
  //       // name: 'Message',
  //       component: () => import('@/views/message/index.vue'),
  //       children: [
  //         {
  //           path: 'detail/:randomId',
  //           name: 'MessageDetail',
  //           component: () => import('@/views/message/detail/index.vue')
  //         }
  //       ]
  //     },
  //   ]
  // }

  // {
  //   path: '/',
  //   component: Layout,
  //   redirect: '/rectify'
  // },
  // {
  //   path: '/rectify',
  //   component: Layout,
  //   redirect: '/rectify/list',
  //   meta: {
  //     title: '审核整改'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       name: 'RectifyList',
  //       component: () => import('@/views/rectify/list/index.vue'),
  //       meta: {
  //         title: '整改列表'
  //       }
  //     },
  //     {
  //       path: 'detail',
  //       name: 'RectifyDetail',
  //       component: () => import('@/views/rectify/detail/index.vue'),
  //       meta: {
  //         title: '整改详情'
  //       }
  //     },
  //     {
  //       path: 'reportList',
  //       name: 'ReportList',
  //       component: () => import('@/views/rectify/reportList/index.vue'),
  //       meta: {
  //         title: '投诉列表'
  //       }
  //     },
  //     {
  //       path: 'reportDetail',
  //       name: 'ReportDetail',
  //       component: () => import('@/views/rectify/reportDetail/index.vue'),
  //       meta: {
  //         title: '投诉详情'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/statistics',
  //   component: Layout,
  //   redirect: '/statistics/total',
  //   meta: {
  //     title: '数据统计'
  //   },
  //   children: [
  //     {
  //       path: 'total',
  //       name: 'StatisticsTotal',
  //       component: () => import('@/views/statistics/total/index.vue'),
  //       meta: {
  //         title: '统计总数据'
  //       }
  //     },
  //     {
  //       path: 'post',
  //       name: 'StatisticsPost',
  //       component: () => import('@/views/statistics/post/index.vue'),
  //       meta: {
  //         title: '职位统计表'
  //       }
  //     }
  //   ]
  // },
  // {
  //   path: '/resource',
  //   component: Layout,
  //   redirect: '/resource/cate',
  //   meta: {
  //     title: '资源位管理配置'
  //   },
  //   children: [
  //     {
  //       path: 'cate',
  //       name: 'ResourceCate',
  //       component: () => import('@/views/resource/cate/index.vue'),
  //       meta: {
  //         title: '资源位类目'
  //       },
  //     },
  //     {
  //       path: 'configList',
  //       name: 'ConfigList',
  //       component: () => import('@/views/resource/configList/index.vue'),
  //       meta: {
  //         title: '配置列表'
  //       }
  //     },
  //     {
  //       path: 'posterList',
  //       name: 'PosterList',
  //       component: () => import('@/views/resource/poster/list/index.vue'),
  //       meta: {
  //         title: '海报配置管理'
  //       }
  //     },
  //     {
  //       path: 'posterEdit',
  //       name: 'PosterEdit',
  //       component: () => import('@/views/resource/poster/edit/index.vue'),
  //       meta: {
  //         title: '编辑配置'
  //       }
  //     },
  //     {
  //       path: 'columnList',
  //       name: 'ColumnList',
  //       component: () => import('@/views/resource/column/list/index.vue'),
  //       meta: {
  //         title: '分栏配置管理'
  //       }
  //     },
  //     {
  //       path: 'columnEdit',
  //       name: 'ColumnEdit',
  //       component: () => import('@/views/resource/column/edit/index.vue'),
  //       meta: {
  //         title: '编辑分栏配置'
  //       }
  //     },
  //     {
  //       path: 'navButtonList',
  //       name: 'NavButtonList',
  //       component: () => import('@/views/resource/navButton/list/index.vue'),
  //       meta: {
  //         title: '金刚区配置管理'
  //       }
  //     },
  //     {
  //       path: 'navPic',
  //       name: 'NavPic',
  //       component: () => import('@/views/resource/navPic/index.vue'),
  //       meta: {
  //         title: '卡片配置管理'
  //       }
  //     }
  //   ]
  // },
  
  // {
  //   path: '/merchant',
  //   component: Layout,
  //   redirect: '/merchant/collect',
  //   meta: {
  //     title: '商户信息收集'
  //   },
  //   children: [
  //     {
  //       path: 'collect',
  //       name: 'Collect',
  //       component: () => import('@/views/merchant/collect/index.vue'),
  //       meta: {
  //         title: '商户进驻'
  //       }
  //     },
  //   ]
  // },

  // {
  //   path: '/user',
  //   component: Layout,
  //   redirect: '/user/list',
  //   meta: {
  //     title: '用户管理'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/user/list/index.vue'),
  //       meta: {
  //         title: '用户列表'
  //       }
  //     },
  //     {
  //       path: 'enrollRecord',
  //       component: () => import('@/views/user/enrollRecord/index.vue'),
  //       meta: {
  //         title: '报名记录'
  //       }
  //     },
  //   ]
  // },

  // {
  //   path: '/claim',
  //   component: Layout,
  //   redirect: '/claim/list',
  //   meta: {
  //     title: '报名单赔付'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/claim/list/index.vue'),
  //       meta: {
  //         title: '赔付列表'
  //       }
  //     },
  //   ]
  // },

  // ,{
  //   path: '/post',
  //   component: Layout,
  //   redirect: '/post/list',
  //   meta: {
  //     title: '职位管理'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/post/list/index.vue'),
  //       meta: {
  //         title: '职位列表'
  //       }
  //     },
  //   ]
  // },

  // ,{
  //   path: '/question',
  //   component: Layout,
  //   redirect: '/question/list',
  //   meta: {
  //     title: '常见问题'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/question/list/index.vue'),
  //       meta: {
  //         title: '问题列表'
  //       }
  //     },
  //   ]
  // },
  // {
  //   path: '/fraudGuide',
  //   component: Layout,
  //   redirect: '/fraudGuide/list',
  //   meta: {
  //     title: '防骗指南'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/fraudGuide/list/index.vue'),
  //       meta: {
  //         title: '指南列表'
  //       }
  //     },
  //   ]
  // },

  // ,{
  //   path: '/feedback',
  //   component: Layout,
  //   redirect: '/feedback/list',
  //   meta: {
  //     title: '意见反馈'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/feedback/list/index.vue'),
  //       meta: {
  //         title: '反馈列表'
  //       }
  //     },
  //   ]
  // }

  // ,{
  //   path: '/community',
  //   component: Layout,
  //   redirect: '/community/list',
  //   meta: {
  //     title: '动态审核'
  //   },
  //   children: [
  //     {
  //       path: 'list',
  //       component: () => import('@/views/community/list/index.vue'),
  //       meta: {
  //         title: '动态发布审核'
  //       }
  //     },
  //   ]
  // }
]

const router = new VueRouter({
  mode: process.env.VUE_APP_RUN_ENV == 'test' ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('TOKEN')
  if (token) {
    if (to.path === '/login') {
      next({ path: '/' })
    } else {
      if(store.state.menuList?.length > 0) {
        if(!to.matched || to.matched.length == 0) {
          next({path: '/404'})
        } else {
          next()
        }
      } else {
        getMenusApi().then(res => {
          console.log('路由菜单',res)
          if(res.data.showMenus && res.data.showMenus.length > 0) {
            const menus = createMenu(res.data.showMenus)
            console.log('createMenu', menus)
            store.commit('SET_MENU_LIST', menus)
            let routers = createRouter(res.data.showMenus)
            console.log('createRouter', routers)
            for (const item of routers) {
              router.addRoute(item)
            }
            next({...to, replace: true })
          } else {
            next()
          }
        }).catch(err => {
          $tips({message: err, type: 'error'})
        })
      }
      
    }
  } else {
    if(to.path === '/login') {
      next()
    } else {
      next({path: '/login'})
    } 
  }
})

// 跳转同一路由报错问题
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.push = function(location) {
  return originalPush.call(this, location).catch(err => {})
};
VueRouter.prototype.replace = function(location) {
  return originalReplace.call(this, location).catch(err => {})
};

function createRouter(routelist, level = 1) {
  const list = []
  for (const item of routelist) {
    const obj = {
      path: item.path,
      component: () => import(`@/${item.component}.vue`),
      meta: {
        title: item.name
      }
    }
    if(level == 1 && item.children?.length > 0) {
      // 第一层需设置redirect，因为没有返回redirect字段，默认跳转排序第一的子路由
      Object.assign(obj, {
        redirect: item.path + '/' + item.children[0].path
      })
    }
    if(item.children?.length > 0) {
      obj.children = createRouter(item.children, level + 1)
    }
    list.push(obj)
  }
  return list
}

function createMenu(routelist, prePath = '') {
  const list = []
  for (const item of routelist) {
    if(item.route == 1) {
      const path = (prePath ? prePath + '/' : '') + item.path
      const obj = {
        path,
        name: item.name,
        icon: item.icon
      }
      if(item.children?.length > 0) {
        obj.children = createMenu(item.children, path)
      }
      list.push(obj)
    }
  }
  return list
}

export default router
