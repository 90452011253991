import axios from 'axios'
import store from '@/store'
import md5 from 'js-md5'
import sha256 from 'crypto-js/sha256'
import { getDeviceId, getEncryptKey, deleteEmptyProperty, objToKeyValue, extractBytes, byteToString } from '@/utils/util.js'
import { enc, dec } from '@/utils/sec.js'
import Setting from '@/setting.js'
import zdd from '@/assets/images/zdd.png'
import media from '@/assets/images/media.png'
import { $tips } from '@/plugins/element/element.js'

const oceanPrefix = 'ocean/'
const service = axios.create({ timeout: 60000 })

const headerOpt = {
	'productId': 'ZDD_ADMIN_PC',
	'sVer': '2',
	'token': '',
  'did': getDeviceId(),
}
// let dataKey = '' //加密数据key
// let signKey = '' //签名key

async function baseRequest(options) {
  if(!store.state.dataKey || !store.state.signKey) await initEncryptKeyAsync()
	if(!store.state.taskDataKey || !store.state.taskSignKey) await initTaskEncryptKeyAsync()
	if(store.state.token) headerOpt.token = store.state.token
	const encData = encryptData(options, Object.assign({}, headerOpt, options.headers || {}))
  return new Promise((resolve, reject) => {
    service({
			baseURL: options.isOpera ? Setting.OPERA_REQUEST_URL : Setting.HTTP_REQUEST_URL,
      url: options.isOpera ?  options.url  : oceanPrefix + options.url,
      method: options.method || 'POST',
			headers: {
				...encData.headers,
				'Content-Type': 'application/json; charset=utf-8'
			},
			data: encData.data || '',
			responseType: 'arraybuffer',
    }).then(res => {
      // console.log('响应结果', res)
      if(res.status == 200) {
				let str = ''
				if(options.isOpera) {
					str = dec(new Uint8Array(res.data), store.state.taskDataKey)
				} else {
					str = dec(new Uint8Array(res.data), store.state.dataKey)
				}
        let data = JSON.parse(str)
        // console.log('解密结果', data)
        if(data.code == 200) {
          resolve(data)
        }
        else if(data.code == 203 || data.code == 209 || data.code == 2001 || data.code == 2010) {
					// $tips({message: data.msg, type: 'error'})
          store.commit('LOGOUT')
        }
        else {
          reject(data.msg || '请求失败')
        }
      } else {
        reject('请求失败')
      }
    }).catch(err => {
			console.log(err)
			reject(new Error('请求失败'))
		})
  })
}


function initEncryptKeyAsync() {
	return new Promise((resolve, reject) => {
		if(store.state.dataKey && store.state.signKey) return resolve()
		
		if(process.env.VUE_APP_RUN_ENV == 'production') {
			// 正式环境密钥
			// https://union.xuniu.vip/duck.png
			axios({
				url: zdd,
				method: 'GET',
				responseType: 'arraybuffer',
			}).then(res => {
				console.log('------zdd------', res)
				let bytes = extractBytes(res.data);
				let source = byteToString(bytes)
				const signKey = getEncryptKey(source, 8)
				const dataKey = getEncryptKey(source, 16)
				store.commit('SET_KEY', {signKey, dataKey})
				resolve()
			}).catch(err => {})
			
		} else {
			// 测试环境密钥
			const timer = setTimeout(() => {
				const source = 'wBe20QMheTuM1rMFr2GliBmWGXIGNbnK2Lu3hkSOrnOo9zCjrvYguAZ4bZ816eLyN6tI9TDVv8fepnB0IvQpJVSLsyZr7IoI6zvoZ0wScWtGt84g5EmtZV6oj726ZW2eZYmE7atfNzcOFKnsyDxCpAZmrVndShfKAhpxb2smIulcxSYwmFf9yDdKeC7jgm3NGRLdBSpyfalrzt2cBL1ISF2NpFIn3KmywOsITHDSLvr7lFNVkJcKQLL0ISciBrxTij2VNaNPNE5dfajY2BddUNDwE6ugb6NYxHQsgiwUJL2efPQR7grOqSUia8uGj1CuWwEowxa6XSJ5xzGutjdJvEAByC41nWTRdZCf0S47ZezSqpBZhhrf0Q4rF4XuVYwIKLpe1oh3SWGRXfGYtUXqN6qDvWsHnvwWyUyAaGcP1HOrxe2GwGo1oeJjifwbK3AAms96SwmHnZ84vEB2DWMjyuL6jPtQmOjN1cPLkETXA60sFvy5zTz4zHuYDbmyd1qypquHJQ9rUWFKtHaHZdlgBnefzjwOAL3sFCqidw7gF3VLvIQhqLTC7PjaFq0xWqMJjhFhVOryHb6lsGvxNNhR75Bk9wdsmagdhDzoEyYoVDz32a2mtIoW4x7Qe2jybhEkHdymGqU5vubvX6tRgDsdXAjDsC8eZKmPcKneGF2dGfpFWXiP9b6hSKPhbfyGFPOpvjK2p8SVaNx52a7E1DQ8PunYnCi6qw4MPJQ5u3u1XQktnmKYjQ8sk6KNZemh7ggN3UVgd7O2urrkIWZl4k4UxPYSwB2iQmoN'
				const signKey = getEncryptKey(source, 8)
				const dataKey = getEncryptKey(source, 16)
				store.commit('SET_KEY', {signKey, dataKey})
				resolve()
				clearTimeout(timer)
			}, 100)
		}
	})
}

function initTaskEncryptKeyAsync() {
	return new Promise((resolve, reject) => {
		if(store.state.taskDataKey && store.state.taskSignKey) return resolve()
		axios({
			url: media,
			method: 'GET',
			responseType: 'arraybuffer',
		}).then(res => {
			let bytes = extractBytes(res.data);
			let source = byteToString(bytes)
			const signKey = getEncryptKey(source, 8)
			const dataKey = getEncryptKey(source, 16)
			store.commit('SET_TASK_KEY', {signKey, dataKey})
			resolve()
		}).catch(err => {
			console.log('err', err)
		})
		
	})
}

function encryptData(options, headers) {
	const result = {}
	let dataKey = ''
	let signKey = ''
	if(options.isOpera) {
		dataKey = store.state.taskDataKey //加密数据key
		signKey = store.state.taskSignKey //签名key
	} else {
		dataKey = store.state.dataKey //加密数据key
		signKey = store.state.signKey //签名key
	}
	
	let jsonStr = JSON.stringify(options.data || {})
	// data加密
	let encRes = enc(jsonStr, dataKey) //对数据加密
	result.data = encRes.buffer
	
	// header sign
	let value = md5(encRes);
	let obj = {
		data: value,
		...headers,
	}
	deleteEmptyProperty(obj)
	let shaArr = sha256(objToKeyValue(obj) + signKey)
	headers['sign'] = shaArr.toString()
	result.headers = headers
	
	return result
}


export default baseRequest

