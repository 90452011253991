import MessageContent from "./messageContent";
import MessageContentType from "./messageContentType";

export default class ArticlesMessageContent extends MessageContent {
	taskId;
	taskUserId;
	taskTitle;
	taskProject;
	price;
	serialNo;
	auditPics;
	rejectReason;
	appealTimeout;
	
	constructor(taskId, taskUserId, taskTitle, taskProject, price, serialNo, auditPics, rejectReason, appealTimeout) {
	  super(MessageContentType.ContentType_Task_Card);
	  this.taskId = taskId;
		this.taskUserId = taskUserId;
		this.taskTitle = taskTitle;
		this.taskProject = taskProject;
		this.price = price;
		this.serialNo = serialNo;
		this.auditPics = auditPics;
		this.rejectReason = rejectReason;
		this.appealTimeout = appealTimeout;
	}
	digest() {
	  return "[任务卡片]";
	}
	
	encode() {
	  let payload = super.encode();
		payload.searchableContent = this.taskId
		const obj = {
			tu: this.taskUserId,
			tt: this.taskTitle,
			tp: this.taskProject,
			p: this.price,
			s: this.serialNo,
			ap: this.auditPics,
			rr: this.rejectReason,
			at: this.appealTimeout
		}
		payload.content = JSON.stringify(obj);
		return payload;
	}
	
	decode(payload) {
	  super.decode(payload);
		if(payload.searchableContent) {
			this.taskId = payload.searchableContent
		}
		if (!payload.content) {
			return
		}
		const obj = JSON.parse(payload.content)
		if (!obj) {
			return
		}
		this.taskUserId = obj.tu
		this.taskTitle = obj.tt;
		this.taskProject = obj.tp;
		this.price = obj.p;
		this.serialNo = obj.s;
		this.auditPics = obj.ap;
		this.rejectReason = obj.rr;
		this.appealTimeout = obj.at;
	}
}