import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import im from './modules/im.js'
import JimClient from 'jim-client'

Vue.use(Vuex)
const userId = localStorage.getItem('USER_ID') || ''
export default new Vuex.Store({
  modules: {
    im
  },
  state: {
    dataKey: '',
		signKey: '',
    taskDataKey: '',
    taskSignKey: '',
    userId,
    token: localStorage.getItem('TOKEN') || '',
    uploadConfig: null, // oss配置
    isResourceGray: localStorage.getItem('RESOURCE_GRAY') == 'true' ? true : false, // 资源配置是否灰度
    menuList: [], // 菜单
    easysay: JSON.parse(localStorage.getItem(`EASYSAY_${userId}`)) || [], // 常用语
    authCodes: JSON.parse(localStorage.getItem('AUTH_CODES')) || [], // 权限code列表
    // authCodes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29], // 权限code列表
    authChs: JSON.parse(localStorage.getItem('AUTH_CHS')) || [], // 渠道权限
  },
  getters: {
    isLogin: state => !!state.token
  },
  mutations: {
    SET_KEY(state, data) {
			state.signKey = data.signKey
			state.dataKey = data.dataKey
		},
    SET_TASK_KEY(state, data) {
      state.taskSignKey = data.signKey
			state.taskDataKey = data.dataKey
    },
    LOGIN(state, data){
			state.token = data.token
			state.userId = data.userId
      state.authCodes = data.authCodes || []
      state.authChs = data.authChs || []
      localStorage.setItem('AUTH_CODES', JSON.stringify(data.authCodes || []))
      localStorage.setItem('AUTH_CHS', JSON.stringify(data.authChs || []))
			localStorage.setItem('TOKEN', data.token)
      if(userId != data.userId) {
        state.easysay = JSON.parse(localStorage.getItem(`EASYSAY_${data.userId}`)) || []
        localStorage.setItem('USER_ID', data.userId)
        JimClient.disconnect(true)
      } 
		},
    LOGOUT(state) {
      state.token = ''
      state.menuList = []
      localStorage.removeItem('TOKEN')
      localStorage.removeItem('AUTH_CODES')
      localStorage.removeItem('AUTH_CHS')
      // localStorage.removeItem('USER_ID')
      JimClient.disconnect()
      router.push('/login')
    },
    SET_UPLOAD_CONFIG(state, data) {
      state.uploadConfig = data
    },
    SET_RESOURCE_GRAY(state, val) {
      state.isResourceGray = val
      localStorage.setItem('RESOURCE_GRAY', val)
    },
    SET_MENU_LIST(state, list) {
      state.menuList = list || []
    },
    SET_EASYSAY(state, data) {
      state.easysay = data
      localStorage.setItem(`EASYSAY_${state.userId}`, JSON.stringify(data))
    }
  }
})
